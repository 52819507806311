import { gql } from './codegen';

export const ACCOUNT_BY_CONTEXT = gql(`
  query AccountByContext {
    accountByContext {
      id
    }
  }
`);

export const LIST_REPORTS_V2_BY_ACCOUNT_CONTEXT = gql(`
  query ListReportsV2ByAccountContext {
    listReportsV2ByAccountContext {
      id
      accountId
      projectId
      name
      description
      annotationIds
      annotations {
        id
      }
      pdfGenerateEvents {
        status
        invokeId
        errorMessage
        requestedAt
        requestedByUserId
        pdfFileId
      }
      pdfFile {
        ...on AssetFile{
          id
          signedGetObjectUrl
          signedGetObjectDownloadUrl
        }
      }
      isPdfGenerating
      createdByUserId
      createdAt
      updatedByUserId
      updatedAt
      isDeleted
    }
  }
`);

export const FIND_REPORT_V2_BY_ID = gql(`
  query FindReportV2ById($reportId: ID!) {
    findReportV2ById(reportId: $reportId) {
      id
      accountId
      projectId
      name
      description
      annotationIds
      annotations {
        id
      }
      pdfGenerateEvents {
        status
        invokeId
        errorMessage
        requestedAt
        requestedByUserId
        pdfFileId
      }
      isPdfGenerating
      pdfFile {
        ...on AssetFile{
          id
          signedGetObjectUrl
          signedGetObjectDownloadUrl
        }
      }
      createdByUserId
      createdAt
      updatedByUserId
      updatedAt
      isDeleted
    }
  }
`);

export const READ_REPORT_V2_BY_ID_WITH_PAYLOAD = gql(`
query ReadReportV2ByIdWithPayload($reportId: ID!) {
  readReportV2ByIdWithPayload(reportId: $reportId) {
      id
      accountId
      projectId
      name
      description
      isPdfGenerating
      pdfFileId
      pdfFile {
          ... on AssetFile {
              id
              kind
              accountId
              createdAt
              createdBy
              signedGetObjectUrl
              signedGetObjectDownloadUrl
          }
      }
      createdByUserId
      updatedByUserId
      createdAt
      updatedAt
      isDeleted
      annotations {
          id
          annotationId
          annotation3d {
            positions {
              x
              y
              z
            }
            rotations {
              w
              x
              y
              z
            }
            shapeType
          }
          annotation2d {
            imageFileId
            imageFile {
              ... on ImageFile {
                signedGetObjectUrl
              }
            }
            points {
              x
              y
            }
          }
          templateId
          groupId
          accountId
          projectId
          name
          color
          isActiveVersion
          isDeleted
          createdByUserId
          createdAt
          fields {
              ... on AnnotationDateField {
                  __typename
                  fieldId
                  end
                  start
                  type
                  name
              }
              ... on AnnotationFileField {
                  __typename
                  fieldId
                  files {
                      fileId
                      name
                  }
                  type
              }
              ... on AnnotationImageField {
                  __typename
                  fieldId
                  file {
                      ... on ImageFile {
                          __typename
                          fileName
                          id
                          kind
                          originalUrl
                          thumbnailUrl
                      }
                  }
                  fileId
                  type
              }
              ... on AnnotationSelectField {
                  __typename
                  fieldId
                  optionId
                  type
                  name 
              }
              ... on AnnotationTextField {
                  __typename
                  fieldId
                  text
                  type
                  name
              }
              ... on AnnotationUrlField {
                  __typename
                  fieldId
                  type
                  urls {
                      url
                      name
                  }
              }
          }
      }
      annotationTemplates {
          id
          accountId
          projectId
          level
          name
          description
          createdBy
          createdByUserId
          updatedByUserId
          createdAt
          updatedAt
          fields {
              ... on AnnotationTemplateTextField {
                  id
                  name
                  order
                  type
                  isDeleted
                  isVisible
              }
              ... on AnnotationTemplateFileField {
                  id
                  name
                  order
                  type
                  isDeleted
                  isVisible
              }
              ... on AnnotationTemplateUrlField {
                  id
                  name
                  order
                  type
                  isDeleted
                  isVisible
              }
              ... on AnnotationTemplateDateField {
                  id
                  name
                  order
                  type
                  isDeleted
                  isVisible
              }
              ... on AnnotationTemplateSelectField {
                  id
                  name
                  order
                  type
                  isDeleted
                  isVisible
                  options {
                    color
                    id
                    value
                  }
              }
              ... on AnnotationTemplateImageField {
                  id
                  name
                  order
                  type
                  isDeleted
                  isVisible
              }
          }
      }
  }
}
`);

export const LIST_PROJECTS_BY_ACCOUNT_CONTEXT = gql(`
  query ListProjectsByAccountContext {
    listProjectsByAccountContext {
      id
      name
      projectGroupId
    }
  }
`);

export const LIST_ANNOTATIONS_BY_PROJECT_ID = gql(`
  query ListAnnotationsByProjectId($projectId: ID!) {
    listAnnotationsByProjectId(projectId: $projectId) {
      color
      createdAt
      groupId
      id
      annotationId
      name
      templateId
      annotation2d {
        shapeType
      }
      annotation3d {
        shapeType
      }

      fields {
        ... on AnnotationDateField {
          __typename
          fieldId
          end
          start
          type
        }
        ... on AnnotationSelectField {
          __typename
          fieldId
          optionId
          type
        }
        ... on AnnotationTextField {
          __typename
          fieldId
          text
          type
        }
        ... on AnnotationUrlField {
          __typename
          fieldId
          type
          urls {
            url
            name
          }
        }
      }
    }
  }
`);

export const LIST_ANNOTATION_TEMPLATES_BY_PROJECT_ID = gql(`
  query ListAnnotationTemplatesByProjectId($projectId: ID!) {
    listAnnotationTemplatesByProjectId(projectId: $projectId) {
      id
      accountId
      projectId
      level
      colorFromField
      name
      description
      fields {
        ... on AnnotationTemplateTextField {
          id
          name
          order
          type
          isDeleted
          isVisible
        }
        ... on AnnotationTemplateFileField {
          id
          name
          order
          type
          isDeleted
          isVisible
        }
        ... on AnnotationTemplateUrlField {
          id
          name
          order
          type
          isDeleted
          isVisible
        }
        ... on AnnotationTemplateDateField {
          id
          name
          order
          type
          isDeleted
          isVisible
        }
        ... on AnnotationTemplateSelectField {
          id
          name
          order
          type
          isDeleted
          isVisible
          options {
            color
            id
            value
          }
        }
        ... on AnnotationTemplateImageField {
          id
          name
          order
          type
          isDeleted
          isVisible
        }
      }
      createdBy
      createdByUserId
      createdAt
      updatedByUserId
      updatedAt
    }
  }
`);

export const USERS_BY_ACCOUNTID_WITH_DEACTIVATED = gql(`
  query UsersByAccountIdWithDeactivated {
    usersByAccountIdWithDeactivated {
      displayName
      firstName
      id
      lastName
    }
  }
`);

export const LIST_LAYERS_BY_PROJECT_ID = gql(`
  query ListLayerByProjectId($projectId: ID!) {
    listLayersByProjectId(projectId: $projectId) {
      id
      name
      mainSceneEntityId
      formatType
    }
  }
`);

export const LIST_SCENE_ENTITIES_BY_PROJECT_ID_AND_RENDERER_TYPES = gql(`
  query ListSceneEntitiesByProjectIdAndRendererTypes($projectId: ID!, $rendererTypes: [String]!) {
    listSceneEntitiesByProjectIdAndRendererTypes(projectId: $projectId, rendererTypes: $rendererTypes) {
      id
      rendererType
      parentSceneEntityId
      renderObjectId
      name
      position {
        x
        y
        z
      }
      rotation {
        w
        x
        y
        z
      }
      renderObject {
        ... on AnnotationRenderObject {
          id
          createdAt
        }
      }
    }
  }
`);

export const LIST_PERMISSION_POLICIES_WITH_ACCOUNT_CONTEXT = gql(`
  query ListPermissionPoliciesWithAccountContext(
    $actionType: PolicyActionTypeInput,
    $objectId: String,
    $objectType: PolicyObjectTypeInput,
    $subjectId: String,
    $subjectType: PolicySubjectTypeInput,
  ) {
    listPermissionPoliciesWithAccountContext(
      actionType: $actionType,
      objectId: $objectId,
      objectType: $objectType, 
      subjectId: $subjectId,
      subjectType: $subjectType,
    ) {
      actionType
      objectId
      objectType
      subjectId
      subjectType
    }
  }
`);

export const READ_USER_BY_CONTEXT = gql(`
  query ReadUserByContext {
    readUserByContext {
      id
      accountId
      name {
        first
        last
      }
    }
  }
`);

export const LIST_SUBJECT_TO_OBJECTS_PERMISSION_POLICIES_WITH_EXPANDED_SUBJECT_IDS = gql(`
  query ListSubjectToObjectsPermissionPoliciesWithExpandedSubjectIds($subjectId: String!, $objectIds: [String], $objectTypes: [PolicyObjectTypeInput], $actionTypes: [PolicyActionTypeInput]) {
    listSubjectToObjectsPermissionPoliciesWithExpandedSubjectIds(subjectId: $subjectId, objectIds: $objectIds, objectTypes: $objectTypes, actionTypes: $actionTypes) {
      accountId
      subjectId
      subjectType
      objectId
      objectType
      actionType
      createdAt
      updatedAt
    }
  }
`);

export const CHECK_SUPER_ADMIN = gql(`
  query CheckSuperAdmin {
    checkSuperAdmin
  }
`);
